<template>
  <div wrap class="elevation-1 mb-3">
    <v-toolbar
      wrap
      dark
      color="secondary lighten-2"
      :style="getToolbarHeight()"
    >
      <v-toolbar-title v-text="title"> </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <validation-observer v-slot="{ handleSubmit }" ref="observer">
      <v-form ref="form" @submit.prevent="handleSubmit(submit)">
        <v-row class="mx-0" align="center" justify="center">
          <v-col cols="12" md="4">
            <ValidationProvider
              name="RUN afiliado"
              rules="required|integer"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="affiliate_run"
                prepend-icon="mdi-account-search-outline"
                hint="ingrese run sin puntos ni dígito verificador"
                label="Run afiliado"
                :error-messages="errors"
                clearable
              ></v-text-field>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="4" align="center">
            <v-btn
              rounded
              :loading="isLoading"
              dark
              color="success"
              class="mr-4"
              @click="submit"
              >buscar
            </v-btn>
            <v-btn rounded dark color="error" @click="clear">cancelar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <simple-detail
      v-if="show_affiliate_profile"
      :item="affiliate_info"
      :order="affiliate_order_info"
    ></simple-detail>
    <template
      v-if="affiliate_info.dependents.length > 0 && show_affiliate_profile"
    >
      <v-data-table
        id="affiliate_info_dependents"
        :headers="headersDependents"
        :items="affiliate_info.dependents"
        :items-per-page="5"
        class="container"
      >
        <template v-slot:top>
          <v-toolbar flat color="secondary lighten-2" dark>
            <v-toolbar-title>Cargas familiares del afiliado(a)</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import SimpleDetail from '@/components/base/SimpleDetail'

export default {
  components: {
    SimpleDetail,
  },
  data: () => ({
    title: 'Busqueda de afiliados',
    affiliate_run: '',
    isLoading: false,
    show_affiliate_profile: false,
    affiliate_info: {
      dependents: [],
      get_short_name: '',
      get_last_name: '',
      get_run: '',
      campus: '',
      get_workday_display: '',
      affiliated_at: '',
      get_affiliate_profile: null,
    },
    affiliate_order_info: [
      {
        name: 'Nombres',
        key: 'get_short_name',
        class: 'xs12 sm6 md4  px-2 py-2',
      },
      {
        name: 'Apellidos',
        key: 'get_last_name',
        class: 'xs12 sm6 md4 px-2 py-2',
      },
      {
        name: 'RUN',
        key: 'get_run',
        class: 'xs12 sm6 md4 px-2 py-2',
      },
      {
        name: 'Email',
        key: 'email',
        class: 'xs12 sm6 md4 px-2 py-2',
      },
      {
        name: 'Fecha de afiliación',
        key: 'get_affiliate_profile.affiliated_at',
        class: 'xs12 sm6 md4 px-2 py-2',
      },
      {
        name: 'Campus',
        key: 'get_affiliate_profile.campus',
        class: 'xs12 sm6 md4 px-2 py-2',
      },
      {
        name: 'Tipo de Jornada Laboral',
        key: 'get_affiliate_profile.get_workday_display',
        class: 'xs12 sm6 md4 px-2 py-2',
      },
      {
        name: 'Saldo Ocupado',
        key: 'get_affiliate_profile.get_actual_balance_debit_bonus',
        class: 'xs12 sm6 md4 px-2 py-2',
      },
      {
        name: 'Saldo Disponible',
        key: 'get_affiliate_profile.get_actual_balance',
        class: 'xs12 sm6 md4 px-2 py-2',
      },
    ],
    headersDependents: [
      {
        text: 'RUN',
        align: 'center',
        sortable: true,
        value: 'run',
        width: '30%',
      },
      {
        text: 'Relación',
        align: 'center',
        sortable: false,
        value: 'relationship',
      },
    ],
  }),
  mounted() {},
  computed: {
    url() {
      return '/affiliates'
    },
  },
  methods: {
    getToolbarHeight() {
      if (this.toolbarHeight) {
        return { height: this.toolbarHeight }
      } else {
        return {}
      }
    },
    submit() {
      return this.$refs.observer.validate().then((result) => {
        if (result) {
          this.isLoading = true
          return this.axios
            .post(this.$api(this.url + '/search_affiliate/'), {
              affiliate_run: this.affiliate_run,
            })
            .then(({ data }) => {
              this.$store.dispatch(
                'message/success',
                'Se ha recuperado información con éxito'
              )
              this.show_affiliate_profile = true
              this.affiliate_info = data
            })
            .catch((error) => {
              this.show_affiliate_profile = false
              this.affiliate_info = {
                dependents: [],
                get_short_name: '',
                get_last_name: '',
                get_run: '',
                campus: '',
                get_workday_display: '',
                affiliated_at: '',
                get_affiliate_profile: null,
              }
              this.$emit('error', error)
            })
            .then(() => {
              return this.$nextTick(() => {
                this.isLoading = false
              })
            })
        }
      })
    },
    clear() {
      this.affiliate_run = ''
      this.isLoading = this.show_affiliate_profile = false
      this.affiliate_info = {
        dependents: [],
        get_short_name: '',
        get_last_name: '',
        get_run: '',
        campus: '',
        get_workday_display: '',
        affiliated_at: '',
        get_affiliate_profile: null,
      }
      this.$refs.observer.reset()
    },
  },
}
</script>
