<template>
  <div id="searchAffiliateIndex">
    <affiliate-info />
  </div>
</template>

<script>
import AffiliateInfo from '@/components/affiliates/SearchAffiliate'
export default {
  components: { AffiliateInfo },
  data() {
    return {}
  },
  computed: {},
}
</script>
